<template>
  <v-card>
    <v-card-title class="text-h5 mb-4">Dados de acesso</v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="form.firstName"
                :rules="[rules.nomeRules]"
                label="Nome *"
                outlined
                dense
                persistent-placeholder
                @input="changeInput"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="form.lastName"
                :rules="[rules.sobrenomeRules]"
                label="Sobrenome *"
                outlined
                dense
                persistent-placeholder
                @input="changeInput"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                id="ipt-agmail"
                v-model="form.emailAddress"
                :rules="[rules.emailRules]"
                :error-messages="mailMessage"
                label="E-mail *"
                outlined
                dense
                persistent-placeholder
                @blur="existsEmail()"
                @input="changeInput"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                id="phoneField"
                v-model="form.cellPhone"
                v-mask="'(##) 9####-####'"
                dense
                label="Celular *"
                outlined
                persistent-placeholder
                :rules="[rules.cellRules]"
                placeholder="(00) 00000-0000"
                @change="changeInput"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                id="userField"
                v-model="form.username"
                :rules="[rules.usernameRules, rules.username5Rules]"
                :error-messages="userMessage"
                label="Usuário *"
                dense
                outlined
                persistent-placeholder
                placeholder="Min. 5 caracteres"
                @blur="existsUsername()"
                @change="changeInput"
              />
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col class="px-0">
              <div class="message-obs pt-1">
                <p>* Campos de preenchimento obrigatório</p>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-ag-primary" :disabled="loading || noChange" :loading="loading" @click="salvar()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from '@/services/userService.js';
import { mapActions, mapGetters } from 'vuex';

import { eventBus } from '../../../main.js';

export default {
  name: 'Perfil',

  data() {
    return {
      valid: false,
      hover: false,
      loading: false,
      noChange: true,
      userMessage: '',
      mailMessage: '',
      form: {},
      rules: {
        nomeRules: (v) => (!!v && /^[A-Za-z]+$/.test(v)) || 'Nome deve ser informado',
        sobrenomeRules: (v) => (!!v && /^[A-Za-z]+$/.test(v)) || 'Sobrenome deve ser informado',
        emailRules: (v) =>
          /^[a-zA-Z].*(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || 'E-mail válido deve ser informado.',

        cellRules: (v) =>
          (!!v && /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/.test(v)) ||
          'Número de celular válido deve ser informado',

        usernameRules: (value) => !!value || 'Usuário deve ser informado',
        username5Rules: (value) => /^(?=.*[a-zA-Z]).{5,}$/.test(value) || 'Deve ter 5 caracteres',
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },

  async created() {
    this.form = { ...this.userInfo };
    await this.$store.dispatch('user/getUser');
  },

  methods: {
    ...mapActions('user', ['saveUser', 'getUser']),

    existsEmail() {
      if (this.form.emailAddress !== this.userInfo.emailAddress) {
        UserService.existsEmail(this.form.emailAddress)
          .then((response) => {
            if (response.data === false) {
              this.mailMessage = '';
            } else {
              this.mailMessage = 'E-mail já cadastrado';
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.mailMessage = '';
      }
    },

    existsUsername() {
      if (this.form.username !== this.userInfo.username) {
        UserService.existsUsername(this.form.username)
          .then((response) => {
            if (response.data == false) {
              this.userMessage = '';
            } else {
              this.userMessage = 'Usuário já cadastrado';
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.userMessage = '';
      }
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };

      eventBus.$emit('openToast', toast);
    },

    async salvar() {
      await this.existsUsername();
      if (this.$refs.form.validate() === false) return;

      this.loading = true;
      try {
        await this.$store.dispatch('user/saveUser', this.form);
        this.showToast('Alterações realizadas com sucesso!', 'success', 78);
      } catch (e) {
        this.showToast('Erro ao salvar os dados', 'error', 78);
      }
      this.loading = false;
    },

    async checkEmailAvailability() {
      let currentUser = await UserService.getUser();

      let current = currentUser.data.emailAddress;
      let edited = document.querySelector('#ipt-agmail').value;

      if (current != edited) {
        try {
          let r = await UserService.existsEmail(this.form.emailAddress);
          return !r.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        return true;
      }
    },

    changeInput(event) {
      this.noChange = !event;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/design/variables.scss';

.message-obs {
  font-size: 14px;
  color: $neutral-color-high-medium;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
