var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "text-h5 mb-4" }, [
        _vm._v("Dados de acesso"),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.rules.nomeRules],
                              label: "Nome *",
                              outlined: "",
                              dense: "",
                              "persistent-placeholder": "",
                            },
                            on: { input: _vm.changeInput },
                            model: {
                              value: _vm.form.firstName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "firstName", $$v)
                              },
                              expression: "form.firstName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.rules.sobrenomeRules],
                              label: "Sobrenome *",
                              outlined: "",
                              dense: "",
                              "persistent-placeholder": "",
                            },
                            on: { input: _vm.changeInput },
                            model: {
                              value: _vm.form.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "lastName", $$v)
                              },
                              expression: "form.lastName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "ipt-agmail",
                              rules: [_vm.rules.emailRules],
                              "error-messages": _vm.mailMessage,
                              label: "E-mail *",
                              outlined: "",
                              dense: "",
                              "persistent-placeholder": "",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.existsEmail()
                              },
                              input: _vm.changeInput,
                            },
                            model: {
                              value: _vm.form.emailAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "emailAddress", $$v)
                              },
                              expression: "form.emailAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "(##) 9####-####",
                                expression: "'(##) 9####-####'",
                              },
                            ],
                            attrs: {
                              id: "phoneField",
                              dense: "",
                              label: "Celular *",
                              outlined: "",
                              "persistent-placeholder": "",
                              rules: [_vm.rules.cellRules],
                              placeholder: "(00) 00000-0000",
                            },
                            on: { change: _vm.changeInput },
                            model: {
                              value: _vm.form.cellPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cellPhone", $$v)
                              },
                              expression: "form.cellPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "userField",
                              rules: [
                                _vm.rules.usernameRules,
                                _vm.rules.username5Rules,
                              ],
                              "error-messages": _vm.userMessage,
                              label: "Usuário *",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              placeholder: "Min. 5 caracteres",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.existsUsername()
                              },
                              change: _vm.changeInput,
                            },
                            model: {
                              value: _vm.form.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "username", $$v)
                              },
                              expression: "form.username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c("v-col", { staticClass: "px-0" }, [
                        _c("div", { staticClass: "message-obs pt-1" }, [
                          _c("p", [
                            _vm._v("* Campos de preenchimento obrigatório"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "btn-ag-primary",
              attrs: {
                disabled: _vm.loading || _vm.noChange,
                loading: _vm.loading,
              },
              on: {
                click: function ($event) {
                  return _vm.salvar()
                },
              },
            },
            [_vm._v(" Salvar ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }